import React, { Component } from 'react';
import './Canvas.css';
import {compSize} from './config'

let App: any;

class Canvas extends Component<{app: any}> {
  mainCanvas = React.createRef<HTMLCanvasElement>();
  smallCanvas = React.createRef<HTMLCanvasElement>();

  constructor(props: {app: any}) {
    super(props);
    App = this.props.app;
  }

  render() {
    return (
      <div className='Canvas'>
        <canvas className='main' ref={this.mainCanvas}></canvas>
        <canvas className='small' ref={this.smallCanvas}></canvas>
      </div>
    );
  }

  componentDidMount() {
    [this.mainCanvas, this.smallCanvas].forEach(canvas => {
      if (canvas.current) {
        canvas.current.width = compSize;
        canvas.current.height = compSize;
      }
    })

    const ctx = this.mainCanvas.current!.getContext('2d');
    this.smallCanvas.current!.getContext('2d')!.globalCompositeOperation = 'copy';

    if (ctx) {
      this.draw(ctx);
    }
  }

  componentDidUpdate() {
    const ctx = this.mainCanvas.current!.getContext('2d');
    if (ctx) {
      this.draw(ctx);
    }
  }

  draw = (ctx: CanvasRenderingContext2D) => {
    const base = App.findBaseLayer();

    ctx.clearRect(0, 0, compSize, compSize);

    App.state.layers.forEach((layer: any) => {
      if (!layer.asset.base && base) {
        if (base.asset.translate) {
          ctx.translate(
            compSize * base.asset.translate[0],
            compSize * base.asset.translate[1]
          );
        }
        if (base.asset.scale) {
          ctx.scale(base.asset.scale, base.asset.scale);
        }
      }

      ctx.translate(compSize/2, compSize/2);
      ctx.scale(parseFloat(layer.transform.scale), parseFloat(layer.transform.scale));
      ctx.rotate(parseFloat(layer.transform.rotate) * Math.PI / 180);
      ctx.translate(-compSize/2, -compSize/2);
      ctx.translate(parseFloat(layer.transform.translateX), parseFloat(layer.transform.translateY));

      if (layer.flipped) {
        ctx.scale(-1, 1);
        ctx.drawImage(layer.asset.img, -compSize, 0);
      } else {
        ctx.drawImage(layer.asset.img, 0, 0);
      }

      ctx.resetTransform();
    });

    this.smallCanvas.current!.getContext('2d')!.drawImage(this.mainCanvas.current!, 0, 0);
  }
}

export default Canvas;
