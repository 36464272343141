import React, { Component } from 'react';

import Button from './Button';

let App: any;

class Import extends Component {
  fileInput = React.createRef<HTMLInputElement>();

  constructor(props: {app: any}) {
    super(props);
    App = props.app;
  }

  render() {
    return <>
      <input
        type='file'
        style={{ display: 'none' }}
        ref={this.fileInput}
        onChange={this.onFileChange}
      />
      <Button
        label='Import'
        onClick={this.onButtonClick}
      />
    </>
  }

  onButtonClick = (e: any) => {
    this.fileInput.current?.click();
  }

  onFileChange = (e: any) => {
    if (this.fileInput.current?.files?.length) {
      const file = this.fileInput.current.files[0];

      const reader = new FileReader();
      reader.onloadend = (e) => App.addCustomAsset(file.name, reader.result);
      reader.readAsDataURL(file);
    }
  }
}

export default Import;
