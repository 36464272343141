import React, { Component, Ref } from 'react';
import './Button.css';

type PropsType = {
  onClick?: (e: any) => void;
  _ref?: Ref<any>;
  label?: string;
  icon?: string;
}

class Button extends Component<PropsType> {
  render() {
    return (
      // eslint-disable-next-line
      <a
        className='Button'
        onClick={this.props.onClick}
        ref={this.props._ref}
        title={this.props.label}
      >
        {
          this.props.icon
          ? <img
              src={`icons/${this.props.icon}.svg`}
              alt={this.props.label}
            />
          : this.props.label
        }
      </a>
    );
  }
}

export default Button;
