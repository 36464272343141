import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

window.onSignIn = (googleUser) => {
  const profile = googleUser.getBasicProfile();
  const email = profile.getEmail();
  console.log(email);
  if (email && email.endsWith('@watershedclimate.com')) {
    ReactDOM.render(<App />, document.getElementById('root'));
  } else {
    document.getElementById('root').innerHTML = 'not for you. go away'
  }
}
